.pagination-container {
  display: flex;
  align-items: center
}

.pagination-container > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  --tw-divide-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-divide-opacity))
}

.pagination-container {
  li:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }
  li:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem
  }
  li {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
    a {
      display: flex;
      width: 2.5rem;
      align-items: center;
      justify-content: center;
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
      --tw-text-opacity: 1;
      color: rgba(59, 130, 246, var(--tw-text-opacity));
    }
    a:hover {
      --tw-text-opacity: 1;
      color: rgba(255, 255, 255, var(--tw-text-opacity))
    }
    a {
      transition-duration: 200ms
    }
    &.selected {
      --tw-bg-opacity: 1;
      background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
      a {
        --tw-text-opacity: 1;
        color: rgba(255, 255, 255, var(--tw-text-opacity));
      }
    }
    &.disabled a{
      --tw-text-opacity: 1;
      color: rgba(209, 213, 219, var(--tw-text-opacity));
    }
  }
}