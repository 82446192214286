.crm-order-form {

  .ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-right: 0 !important;
  }

  .order-form-group-date-picker {
    height: 2.1125rem !important;

    .ant-picker-input {
      > input {
        line-height: 1.5715 !important;
        font-size: 0.75rem !important;
      }
    }
  }

  .crm-order-form-block {

  }

  h5 {
    color: #455560 !important;
  }

}

$crm-order-status-map: (
  new: #0069b4,
  on-payment: #72849a,
  paid: #04d182,
  pass-registration: #D77D1A,
  on-shipment: #ffc542,
  shipped: #04d182,
  cancelled: #ff6b72,
);


.crm-order-status {

  @each $status, $color in $crm-order-status-map {

    &-#{$status} {

      & > .ant-badge-status-dot {
        background: $color !important;

        &:after {
          border: 1px solid $color !important;
        }
      }

    }

  }

  &-active {

    & > .ant-badge-status-dot {

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        -webkit-animation: antStatusProcessing 1.2s ease-in-out infinite;
        animation: antStatusProcessing 1.2s ease-in-out infinite;
        content: "";
      }

    }

  }

}
