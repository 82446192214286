.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.overflow-hidden {
  overflow: hidden !important;
}
