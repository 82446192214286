// fix ant-table wrapper

//.ant-table-wrapper {
//  display: flex;
//
//  & > .ant-spin-nested-loading {
//
//    flex-grow: 1;
//    height: 100%;
//    display: flex;
//    flex-direction: column;
//
//    & > .ant-spin-container {
//      height: 100%;
//      flex-grow: 1;
//    }
//
//  }
//
//  .ant-table-body {
//    flex-grow: 1;
//    max-height: unset !important;
//  }
//
//  .ant-table {
//    display: flex;
//    flex-direction: column;
//    flex-grow: 1;
//    height: 100%;
//
//    & > .ant-table-container {
//        display: flex;
//        flex-direction: column;
//        flex-grow: 1;
//        height: 100%;
//
//        & > .ant-table-body {
//          & > table {
//            height: 100%
//          }
//        }
//      }
//
//  }
//
//  .ant-spin {
//    max-height: unset !important;
//  }
//
//}

// ant-form

.ant-form-inline {
  .ant-form-item:last-child {
    margin-right: 0 !important;
  }
}


// fix spinner position
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  margin-left: -10px !important
}

.ant-menu-submenu .ant-menu-title-content {
  margin-left: 0 !important;
}

