/* purgecss start ignore */

.parent-row {
  td:first-child {
    padding-right: 1.5rem !important;
  }
}

.child-row {
  background: #F9FAFB;
  td:first-child {
    padding-left: 1.5rem !important;
  }
}

/* purgecss end ignore */