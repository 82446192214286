

.my-layout-sider {
  //width: 60px !important;
  //max-width: 60px !important;
  //min-width: 60px !important;

  .my-custom-menu {
    .ant-menu-item {
      margin-bottom: 0;
    }
  }

  &__logo-wrapper {
    height: 60px;
    //padding-top: 10px;
    //background: rgba(53,115,179,.1);
  }

  &__logo-wrapper-inner {
    margin: 10px 10px 10px 20px;
    position: relative;
    height: 40px;
    overflow: hidden;
  }

  &__logo {
    transition: all .4s cubic-bezier(.05,.74,.27,.99);
    position: absolute;
    right: -138px;
    height: 40px
  }

  &:hover {
    .my-layout-sider__logo {
      right: auto;
      left: 0
    }
  }

  .ant-menu-inline-collapsed {
    width: 60px !important;

    .ant-menu-item {
      text-align: center;
    }
  }

  .ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 10px;
  }

  .my-layout-sider-children {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .sidebar-avatar {

    &__wrapper {
      width: 100%;
      padding: 5px;
      background: rgba(#3573B3, 0.1);
      text-align: left;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
    }

    &__name-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__name {
      margin-bottom: 0;
      padding-left: 5px;
    }

    &__avatar {
      margin-right: 5px;
    }

    &:hover {
      background: #3573B3;
    }

  }

  &--don-ugli {

    .my-layout-sider__logo-wrapper {
      height: 70px;
    }

    .my-layout-sider__logo-wrapper-inner  {
      height: 60px;
    }

    .my-layout-sider__logo  {
      height: 60px;
      right: -89px;
    }

  }

  &--rwsp {

    .my-layout-sider__logo-wrapper {
      height: 70px;
    }

    .my-layout-sider__logo-wrapper-inner  {
      height: 25px;
    }

    .my-layout-sider__logo  {
      height: 25px;
      right: -149px;
    }

  }

}

td.ant-table-cell, tr.ant-table-cell {

  &::not(::first-child) {
    position: static !important;
  }
}

.nav-profile-body {

  .ant-menu-inline-collapsed {
    width: auto;
  }

  .ant-menu-item {
    .anticon + span {
      max-width: none;
      opacity: 1;
    }
  }
}

//.bdds-table .ant-table {
//  font-size: 0.65em !important;
//
//  & td.ant-table-cell {
//    padding: 0.7em !important;
//
//    &:not(:first-child) {
//      white-space: nowrap;
//    }
//  }
//}

.app-content {
  padding: 0;
}

.bdds-page-header {
  padding: 10px
}

.ant-table-row:hover {
  td {
    background: lighten(orange, 40) !important;
  }
}

// LOGIN page logo
.login-main-form {
  .img-fluid {
    max-width: 50%;
    height: auto;
    margin-bottom: 30px;
  }
}

.ant-tooltip {
  font-size: 0.65rem
}

.side-nav {
  left: -200px !important;
  transition: all .4s cubic-bezier(.05,.74,.27,.99);
  right: auto;

  &--hover {
    left: 0!important;
  }

  &:hover {
    left: 0 !important
  }

  .my-layout-sider-children {
    height: 100%;
  }

  .my-custom-menu {
    .ant-menu-item {
      text-align: left;
      .anticon {
        line-height: 2.5rem;
        vertical-align: middle;
        float: right;
        margin-right: 5px;
        margin-left: 15px;
      }
    }
  }

  width: 280px;
}

.bdds-year-picker-dropdown {
  width: 70px !important;
  .ant-dropdown-menu {
    min-width: 0;
    width: 70px;
  }
  .ant-dropdown-menu-item {
    text-align: center;
  }
}

.page-card {


  .ant-card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & > div {
      flex-grow: 1;
    }
  }
}

.custom-card-no-padding {

  &__title {
    padding: 1.25rem 1.25rem;
    //position: absolute;
    //width: 100%;
  }

  .ant-card-body {
    padding: 0
  }

}


.height-100 {
  height: 100%;

  .ant-card-body {
    height: 100%
  }
}

.ant-card {
  overflow: hidden;
}

.bdds-balance-widget {
  //margin-bottom: 0 !important;
  flex-grow: 2
}

.custom-label-radio-group {
  & > label {
    height: 24px !important;
    line-height: 22px !important;
    padding: 0 8px !important;
    border-radius: 4px !important;

    &:before {
      display: none !important;
    }

    &:not(:last-child) {
      margin-right: 5px !important;
    }

    &.ant-radio-button-wrapper-checked {
      background: $btn-link-disabled-color !important;
      border-color: $btn-link-disabled-color !important;
    }

  }
}

.custom-list-no-padding {
  .ant-list-item {
    padding-left: 0 !important;
    padding-right: 0 !important;

    &:first-child {
      padding-top: 0 !important;
    }
  }
}

.flex-grow-5-cols {
  flex-basis: 20%;
}

// stretch full height card in <Col>

.ant-col {
  display: flex;
  flex-direction: column;

  & > .ant-card {
    flex-grow: 1;
  }
}

// RESPONSIVE H2-FONT SIZE

$h5-font-size-map: (
        xs: $font-size-sm,
        sm: $font-size-sm,
        md: $font-size-sm,
        lg: $font-size-sm,
        xl: $font-size-base
) !default;

$h4-font-size-map: (
        xs: $font-size-md,
        sm: $font-size-md,
        md: $font-size-md,
        lg: $font-size-sm,
        xl: $font-size-md
) !default;

$h1-font-size-map: (
        xs: $font-size-xl,
        sm: $font-size-md,
        md: $font-size-lg,
        lg: $font-size-xl,
        xl: $font-size-xxl
) !default;

$h2-font-size-map: (
        xs: $font-size-sm,
        sm: $font-size-sm,
        md: $font-size-md,
        lg: $font-size-lg,
        xl: $font-size-xl
) !default;

@each $breakpoint in map-keys($grid-breakpoints) {
  // $infix: breakpoint-infix($breakpoint, $breakpoints);

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .h5-responsive {
      font-size: map-get($h5-font-size-map, $breakpoint)
    }
    .h4-responsive {
      font-size: map-get($h4-font-size-map, $breakpoint)
    }
    .h2-responsive {
      font-size: map-get($h2-font-size-map, $breakpoint)
    }
    .h1-responsive {
      font-size: map-get($h1-font-size-map, $breakpoint)
    }
  }

}

.custom-echart {
  height: unset !important;

  .echarts-for-react {
    height: unset !important;
    display: flex;

    & > div:first-child {
      margin-top: auto;
    }
  }
}

.ant-button--focused {
  background-color: #ededed;
  border-color: #ededed;
  color: #455560;
}

.ant-tooltip-inner {
  white-space: pre-line;
  font-size: 1.1em
}

.ant-card-body {
  height: 100%
}

@mixin vertical-align($align: middle) {
  &:after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: $align;
    // you can add font-size 0 here and restore in the children to prevent
    // the inline-block white-space to mess the width of your elements
    font-size: 0;
  }
  & > * {
    vertical-align: $align;
    // although you need to know the font-size, because "inherit" is 0
    //font-size: 14px;
  }
}

.__v-c {
  @include vertical-align(middle)
}

.title-dot {
  display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px
}

.custom-period-selector {
  min-width: 36px;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;

  .ant-picker-input {
    display: none;
  }
  .ant-picker-range-separator {
    display: none;
  }
  .ant-picker-suffix {
    margin-left: 0 !important
  }
}

.ant-table-wrapper {
  background: white
}

// fix menu
.ant-menu-submenu-title {
  display: flex;
  align-items: center;

  & > span:nth-child(2) {
    order: 1;
    margin-right: 5px;
  }

  & > .anticon {
    order: 3;
    margin-right: -12px;
    margin-left: auto;
  }

  .ant-menu-title-content {
    margin-left: 0 !important;
    display: inline;
    flex-grow: 0 !important;
  }

  & > .ant-menu-submenu-arrow {
    order: 2;
    flex-grow: 1;
    position: static !important;
  }
}

.ant-modal-wrap {
  overflow: hidden !important
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
  span:last-child {
    text-align: left;
    white-space: break-spaces;
  }
}

.custom-datepicker {

  &--no-clear {

    .ant-picker-clear {
      display: none !important;
    }

  }

}

.custom-datepicker-popup {

  &--all-days-highlighted {

    .ant-picker-cell.ant-picker-cell-disabled {
      &:before {
        display: none;
      }
    }

    .ant-picker-cell:not(.ant-picker-cell-selected):not(.ant-picker-cell-today):not(.ant-picker-cell-disabled) {

        .ant-picker-cell-inner {
          background: $tone-geekblue !important;
          color: white !important
        }

    }

    //.ant-picker-cell {
    //
    //  &:not(.ant-picker-cell-selected), &:not(.ant-picker-cell-today) {
    //
    //    .ant-picker-cell-inner {
    //      background: red !important
    //    }
    //
    //  }
    //
    //}

  }

}

.custom-date-picker {
  &--no-input {
    //display: none;
  }
}

.custom-single-file-upload {
  .ant-upload-select {
    width: 100%
  }
}
