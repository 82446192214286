/* purgecss start ignore */

.react-daterange-picker__wrapper {
  border-color: rgba(209, 213, 219, 1) !important;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 0.375rem;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity))
}

.react-daterange-picker__wrapper:focus {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity))
}

.react-daterange-picker__wrapper {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.react-daterange-picker__wrapper > * {
  line-height: 1.5rem
}

.react-daterange-picker__wrapper {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.react-daterange-picker__wrapper:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5
}

.react-daterange-picker__wrapper {
  .react-daterange-picker__inputGroup {
    min-width: unset;
  }
}

.react-daterange-picker__calendar {
  padding: 1rem 0;
  .react-calendar {
    overflow: hidden;
    border-radius: 0.375rem;
    .react-calendar__navigation {
      margin-bottom: 0;
    }
    .react-calendar__year-view {
      .react-calendar__tile {
        padding: 0;
        abbr {
          line-height: 1.975rem;
        }
      }
    }
  }
}

.ant-picker-range {
  border-radius: 0.375rem;
  border-color: rgba(209, 213, 219, 1);
  height: 100%;
  max-height: 42px;
  .ant-picker-input {
    input {
      font-size: 1rem;
      color: rgba(69, 85, 96, 1);
      &::-webkit-input-placeholder {
        color: rgba(69, 85, 96, 1) !important;
      }
      &:-ms-input-placeholder {
        color: rgba(69, 85, 96, 1) !important;
      }
      &::placeholder {
        color: rgba(69, 85, 96, 1) !important;
      }
    }
  }
  &.small {
    .ant-picker-input {
      input {
        width: 80px;
        text-align: center;
      }
    }
  }
}

/* purgecss end ignore */

