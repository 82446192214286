.multi-select {
    border: 1px rgba(209, 213, 219, 1) solid;
}

.multi-select-focus {
    border: 1px rgba(165, 180, 252, 1) solid !important;
}

.multi-select:focus {

}

.multi-select .anticon.anticon-close {
vertical-align: 0;
}