.logo {
    height: $header-height;
    display: flex;
    align-items: center;
    //padding: 0 1rem;
    background-color: transparent;
    transition: all .2s ease;
    align-self: center;

    & > img {
        display: block;
        width: 100%
    }
}
