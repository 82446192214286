/* purgecss start ignore */

.svg-inline--fa {
  width: 1rem !important;
  &.fa-edit {
    position: relative;
    left: 2px;
  }
}

/* purgecss end ignore */