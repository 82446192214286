.scrumboard {
  .board-column {
    border: 1px solid #cadeec;
  }

  .board-card {

    padding: 0.8rem !important;

    .crm-order-card-badge {
      &.bg-info {

      }
    }

    .download-order-button {
      padding: 0.15rem 0.7rem;
      height: 1.7rem;
      margin-left: -10px;

      padding: 0.15rem 0.7rem;
      height: 1.7rem;
      border-radius: 0.325rem;
      border-radius: 0.325rem;
    }

    .ant-tag.bg-gray-lightest{
      height: 2rem !important;
      line-height: 2.6 !important;
      margin-right:0px !important;
    }

    .ant-list-item-meta-avatar {
      margin-right: 20px;
    }

    .ant-list-item-meta {
      align-items: center;
    }

    .ant-list-item-meta-title {font-size: 12px;}

    li.ant-list-item {padding: 7px 0 7px 0px;}

    ul {
      margin-bottom: 10px;
    }

    .board-label { height: 4px;}

    .board-card-item-data {
      text-align: left;
    }

  }
}

.download-order-button {
  color: #0069b4;
  background-color: rgba(0, 105, 180, 0.1);

  &:focus {
    background-color: rgba(0, 105, 180, 0.1);
  }
}
