.bochka-widget {

  &__number {
  }


  .st0{fill:#7676e5; transition: .2s all ease-in}
  .st1{fill:#010101;}
  .st2{display:none;}
  .st3{display:inline;fill:#010101;}

}
