/* purgecss start ignore */

.Toastify {
  position: absolute;
}

.Toastify__toast {
  background: rgba(0,0,0,0.6);
  border-radius: 4px;
  padding: 18px;
  box-sizing: border-box;
  &-body {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.275px;
    font-weight: 400;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    &:before {
      content: "";
      display: block;
      background: url("/img/svg/success.svg") no-repeat center;
      width: 34px;
      height: 34px;
      margin-right: 12px;
      margin-top: 2px;
      flex: 0 0 auto;
    }
  }
  &--error {
    .Toastify__toast-body {
      &:before {
        background-image: url("/img/svg/error.svg");
      }
    }
  }
  .Toastify__close-button {
    display: none;
  }
}

/* purgecss end ignore */