@import './variables/variables.scss';
@import './mixins/mixins.scss';

//*********************//
//**** Components ****//
//*******************//

// General
@import './components/general/typorgraphy';
@import './components/general/button';

// Layout
@import './components/layout/layout';

// Navigation
@import './components/navigation/affix';
@import './components/navigation/breadcrumb';
@import './components/navigation/dropdown';
@import './components/navigation/menu';
@import './components/navigation/page-header';
@import './components/navigation/pagination';
@import './components/navigation/steps';

// Data Entry
@import './components/data-entry/cascader';
@import './components/data-entry/checkbox';
@import './components/data-entry/date-picker';
@import './components/data-entry/input';
@import './components//data-entry/input-number';
@import './components/data-entry/form';
@import './components/data-entry/mention';
@import './components/data-entry/radio';
@import './components/data-entry/rate';
@import './components/data-entry/select';
@import './components/data-entry/slider';
@import './components/data-entry/switch';
@import './components/data-entry/tree-select';
@import './components/data-entry/time-picker';
@import './components/data-entry/transfer';
@import './components/data-entry/upload';

// Data Display
@import './components/data-display/avatar';
@import './components/data-display/badge';
@import './components/data-display/comment';
@import './components/data-display/collapse';
@import './components/data-display/carousel';
@import './components/data-display/card';
@import './components/data-display/calendar';
@import './components/data-display/descriptions';
@import './components/data-display/empty';
@import './components/data-display/list';
@import './components/data-display/popover';
@import './components/data-display/statistic';
@import './components/data-display/tree';
@import './components/data-display/tooltip';
@import './components/data-display/timeline';
@import './components/data-display/tag';
@import './components/data-display/tabs';
@import './components/data-display/table';

// Feedback
@import './components/feedback/alert';
@import './components/feedback/drawer';
@import './components/feedback/message';
@import './components/feedback/modal';
@import './components/feedback/notifications';
@import './components/feedback/progress';
@import './components/feedback/skeleton';
@import './components/feedback/spin';

// Other
@import './components/others/anchor';
@import './components/others/back-to-top';
@import './components/others/divider';

// Pages
@import './components/pages/bdds-page';
@import './components/pages/crm/board';
@import './components/pages/crm/form';
@import './components/pages/home/widgets/bochka';
@import './components/pages/registry-payments/registry-payments-search-drawer';

//****************************//
//**** Custom Components ****//
//**************************//

@import './custom-components/ellipsis-dropdown';
@import './custom-components/code-box';
@import './custom-components/api-container';
@import './custom-components/icon-display';
@import './custom-components/icon-svg';

//****************************//
//********** Apps ***********//
//**************************//

@import './apps/mail';
@import './apps/chat';
@import './apps/calendar';
@import './apps/scrumboard';

//****************************//
//********* Vendors *********//
//**************************//

@import './vendors/apex-chart';
@import './vendors/quill';

//****************************//
//******** Template *********//
//**************************//

@import './template/header';
@import './template/side-nav';
@import './template/top-nav';
@import './template/logo';
@import './template/page-header';
@import './template/page-header-alt';
@import './template/nav-notification';
@import './template/nav-profile';
@import './template/content';
@import './template/nav-search';
@import './template/footer';

@import "./layout";


//@import '~antd/dist/antd.compact.css';
@import "./fix.scss";
@import "./ant-tag.scss";



body {
  background: #f3f3f3 !important
}

.label-required {
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.text-icon {
  flex-shrink: 0;
  font-size: 0.75rem;
  line-height: 1rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(#646464, 0.6);
  color: #fff;
  transition: background-color 0.2s;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  &:hover {
    background: #414141;
  }
}

.form-hidden {
  &.ant-row.ant-form-item {
    .ant-form-item-control-input {
      min-height: unset !important;
      height: 0 !important;
    }
  }
}