.value-negative {
  color: #bb0d00
}
.value-positive {
  color: $green
}

.cursor-pointer {
  cursor: pointer;
}


.bdds-page {



  .ant-picker.bdr-period-picker {

    .ant-picker-input {
      border-bottom: 1px dotted black !important;
      input {
        cursor: pointer !important;
      }
    }

  }

  &._bdds-view-months {

    .ant-table-thead {

      tr:first-child {
        th.ant-table-cell-fix-right-first {
          right: var(--scroll-size) !important
        }
      }

      tr:last-child {
        th.ant-table-cell-fix-right-first {
          right: calc(100px + var(--scroll-size)) !important
        }
      }
    }

    .ant-table-cell-fix-right-first {
      right: 100px !important
    }

  }

  .bdds-page__table {
    flex-grow: 1;

    .bdds-table-data-column {
      text-align: center;
    }

    table {
      table-layout: fixed;
      will-change: scroll-position;
    }

    .ant-table-body {
      will-change: scroll-position;

    }

    .ant-table-header {
      //will-change: scroll-position;

    }

    /*.ant-table-cell {
      &::after {
        display: none;
      }
    }*/

    .ant-table-cell {
      transition: none !important;
    }

    .ant-table table {
      border-collapse: separate;
    }

    .ant-table {
      font-size: 0.70em !important;

      & td.ant-table-cell:not(.bdds-data-cell) {
        padding: 0.7em !important;

        &:not(:first-child) {
          white-space: nowrap;
        }
      }

      .ant-table-thead {

        th.bdds-table-group-columns {
          cursor: pointer;
        }

        .bdds-table-data-column {
          text-align: center;
        }

        th.ant-table-cell {
          border-bottom: 1px solid rgba(230, 230, 230, 0.7);
          border-right: 1px solid #eceeef;
          // color: rgba(44, 44, 44, 0.35);
          background: white;
          padding: 5px;
          text-align: center;
        }

      }

      tr {
        & > th:first-child {
        }
        & > td:first-child {
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

    }
  }

  .ant-table-cell.bdds-data-cell {
    padding: 0 !important;
    text-align: center;

    & > div {
      width: 100%;
      height: 100%;
    }

    & > input {
      display: block;
      width: 100%;
      height: 100%;
      background: transparent;
      border: none;
      text-align: center;
      outline: none;
    }
  }

  .bdds-table-row--level-3 {

    .inputable-cell:hover {
      background: lighten(orange, 20) !important;
    }

  }

  .ant-table-row {
    height: 1px;
    min-height: 1px;

    & > td {
      height: inherit;
    }

    @-moz-document url-prefix('') {

      & > td {
        height: 100%;
      }
    }

  }

  .bdds-table-row--level-3 {

    .inputable-cell {
      cursor: pointer;
    }

  }

  .ant-table-summary .ant-table-cell {
    border-right: 1px solid #edf2f9;
  }

  .bdds-data-cell {
    border-right: 1px solid #edf2f9;

    & > div {
      border: 1px solid transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    input:focus {
      border: 1px solid rgba(grey, .7) !important;
    }
  }

}

.bdds-payments-list {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;

  .ant-list-item-meta-title {
    opacity: .6
  }

  .ant-list-item-meta-description {
    color: $gray-dark
  }
}

.bdds-payments-modal {
  .ant-modal-body {
    padding-top: 5px
  }
}


.bdds-widget {
  position: relative;
  &__substrate {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
  }

  &__card {

  }
}

// payments modal

.bdds-payments-list-item {

  .ant-list-item-meta {
    margin-bottom: 0 !important;
  }

  // дата счета
  .ant-list-item-meta-title {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 0 !important;
  }

  // контерпати
  .ant-list-item-meta-description {
    font-weight: 600;
    font-size: 14px;
  }

  .ant-list-item-extra {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


}

.bdds-page {

  .bdds-page__table {

    .ant-table-row-level-0 > td {
      background-color: #c1e0ff;
    }

    .ant-table-row-level-1 > td {
      background-color: #e8f3ff;
    }

    .ant-table-row-level-2 > td {
    }

    .ant-table-summary {
      td {
        background-color: #c1e0ff;
      }
    }

  }

}

// THEME-DON-UGLI

.theme-don-ugli {

  .bdds-page {

    .bdds-page__table {

      .ant-table-row-level-0 > td {
        background-color: #d6d4c6;
      }

      .ant-table-row-level-1 > td {
        background-color: #f5f1d7;
      }

      .ant-table-row-level-2 > td {
        background-color: #feffde;
      }

      .ant-table-summary {
        td {
          background-color: #d6d4c6;
        }
      }

    }

    .bdr-table {

      .ant-table-row-level-1 > td {
        background-color: #fffded;
      }

      .ant-table-row-level-2 > td {
        background-color: #ffffff;
      }

      .ant-table-summary {
        td {
          background-color: #d6d4c6;
        }
      }

    }

  }

}

.bdds-comment-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.bdds-comment-popover {
  .ant-popover-inner-content {
    padding: 0 !important;
    .ant-typography {
      left: 0 !important
    }
  }
}
