.icon-svg {
  width: 1em;
  height: 1em
}

.icon-svg-height-19px {
  height: 19px !important;
}

.icon-svg {
  display: inline-block;
  line-height: 0;
  flex-shrink: 0;
  flex-grow: 0;
  //color: $gray-dark;
  transition: color 0.15s ease-in, opacity 0.15s ease-in;
  //width: 14px;
  //height: 14px;

  &._rotate180 {
    transform: rotate(180deg);
  }

  &._rotate90 {
    transform: rotate(90deg);
  }

  &._rotateInvert-90 {
    transform: rotate(-90deg);
  }

  &._8x8 {
    width: 8px;
    height: 8px;
  }

  &._11x11 {
    width: 11px;
    height: 11px;
  }

  &._12x12 {
    width: 12px;
    height: 12px;
  }

  &._14x14 {
    width: 14px;
    height: 14px;
  }

  &._16x16 {
    width: 16px;
    height: 16px;
  }

  &._18x18 {
    width: 18px;
    height: 18px;
  }

  &._20x20 {
    width: 20px;
    height: 20px;
  }

  &._25x25 {
    width: 25px;
    height: 25px;
  }

  &._32x32 {
    width: 32px;
    height: 32px;
  }

  &._40x40 {
    width: 40px;
    height: 40px;
  }

  &._white {
    color: #fff;
  }

  &._blue {
    color: #3382BD !important
  }

  &._deep-black {
    color: rgba(#141414, 0.5);
  }

  &._red {
    fill: #FF0000;
  }

}
